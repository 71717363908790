import React, {FC} from "react";
import styled from "styled-components";
import {theme} from "../../../styles/theme";
import ContentWrapper from "../../../components/ContentWrapper";
import MaterialItem from "./MaterialItem";
import {removeAdvertisement} from "../../../actions/offlineAdvertising";
import {useFetching} from "../../../hooks/useFetching";
import {
    BUS_STOP_VALUE,
    ELEVATORS_VALUE,
    ENTRANCES_VALUE, ISelectOption,
    MAGAZINE_VALUE,
    MATERIALS_VALUE,
    OTHER_VALUE,
    POST_BOX_VALUE,
    PROMOTER_VALUE,
    TRANSPORT_VALUE
} from "../AddModal/const";
import Loader from "../../../components/Loader";
import Page404 from "../../Page404";

const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;

    @media (max-height: 700px) {
        .grid-content-wrapper {
            overflow: unset;
            height: auto;
        }
    }
`;

const MsgWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(3, 173, 0, 0.1);
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 16px;
`;

const MsgHeader = styled.p`
  line-height: 25px;
    font-family: ${theme.fonts.mainBlack};
  font-size: ${theme.fontSizes.h3};
  color: ${theme.colors.bodyText};
`;

const List = styled.ul`
  line-height: 20px;
  font-family: ${theme.fonts.mainRegular};
  font-size: ${theme.fontSizes.body};
  color: ${theme.colors.bodyText};
  margin-left: 25px;
  margin-top: 10px;
`;

const BlockWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-bottom: 1px solid #F1F3F5;
`;

const BlockTitle = styled.div`
  line-height: 20px;
  font-family: ${theme.fonts.mainBlack};
  font-size: ${theme.fontSizes.h3};
  color: ${theme.colors.bodyText};
`;

interface Props {
    data: object;
    offlineAdvertisingType: ISelectOption[];
    isLoading: boolean;
    error: string;
    fetchOfflineAdvertising: () => void;
}

const Content: FC<Props> = ({
    data,
    offlineAdvertisingType,
    isLoading,
    error,
    fetchOfflineAdvertising
}) => {
    const [fetchDelete, isLoadingDelete, errorDelete] = useFetching(async (id) => {
        const response = await removeAdvertisement(id);
        if(response.message === "OK") fetchOfflineAdvertising();
    })

    const modifyData = (type, data) => {
        switch (type) {
            case MATERIALS_VALUE:
                return [
                    {
                        title: "Печатная продукция",
                        value: data.printed_product.name_ru
                    },
                    {
                        title: "Размер материалов",
                        value: data.poster_size.name_ru
                    },
                    {
                        title: "Количество",
                        value: data.products_count
                    },
                    {
                        title: "Комментарий",
                        value: data.comment
                    }
                ];
            case BUS_STOP_VALUE:
            case ELEVATORS_VALUE:
                return [
                    {
                        title: "Желаемые адреса",
                        value: data.accommodation_addresses
                    },
                    {
                        title: "Комментарий",
                        value: data.comment
                    }
                ];
            case TRANSPORT_VALUE:
                return [
                    {
                        title: "Желаемые номера маршрутов",
                        value: data.route_numbers
                    },
                    {
                        title: "Комментарий",
                        value: data.comment
                    }
                ];
            case POST_BOX_VALUE:
            case ENTRANCES_VALUE:
                return [
                    {
                        title: "Желаемые адреса",
                        value: data.accommodation_addresses
                    },
                    {
                        title: "Комментарий",
                        value: data.comment
                    }
                ];
            case PROMOTER_VALUE:
                return [
                    {
                        title: "Желаемые точки раздачи листовок",
                        value: data.leaflet_distribution_points
                    },
                    {
                        title: "Комментарий",
                        value: data.comment
                    }
                ];
            case MAGAZINE_VALUE:
                return [
                    {
                        title: "Наименование печатных изданий",
                        value: data.print_media_name
                    },
                    {
                        title: "Комментарий",
                        value: data.comment
                    }
                ];
            case OTHER_VALUE:
                return [
                    {
                        title: "Комментарий",
                        value: data.comment
                    }
                ];
        }
    }

    return (
        <Wrapper>
            <MsgWrapper>
                <MsgHeader>Внимание!</MsgHeader>
                <List>
                    <li>
                        Заказы на <b>HR материалы для размещения в ресторанах</b> необходимо размещать в системе до 10 числа текущего месяца.
                        <br/>
                        Заявки, сделанные после этой даты, не принимаются.
                        <br/>
                        <b>Например</b>, до <b>10 октября</b> необходимо заказать все материалы на <b>Ноябрь</b>.
                        <br/>
                        <b>Необходимо в правом верхнем углу выбрать месяц Ноябрь.</b>
                        <br/>
                        <br/>
                        ПРИМЕР ЗАКАЗА HR МАТЕРИАЛОВ ДЛЯ РАЗМЕЩЕНИЯ В РЕСТОРАНАХ НА НОЯБРЬ:
                        <br/>
                        <RedSpan>
                            1.10.24 – 10.10.24
                        </RedSpan>
                        <br/>
                        Заказ HR материалов на ноябрь 2024 в MARS &#8658;
                        <br/>
                        <RedSpan>
                            11.10.24 – 13.10.24
                        </RedSpan>
                        <br/>
                        Формирование и размещение заказа &#8658;
                        <br/>
                        <RedSpan>
                            14.10.24 – 28.10.24
                        </RedSpan>
                        <br/>
                        Изготовление и отправка материалов в рестораны.
                        <br/>
                        <br/>
                        Размещение <b>наружной рекламы в городе</b> отделом по подбору персонала выполняется самостоятельно на основании предоставленных ТУ данных об укомплектованности каждого ресторана и его потребности на следующий месяц. Размещение осуществляется для ресторанов с наименьшей заполненностью.
                    </li>
                </List>
            </MsgWrapper>
            <ContentWrapper>
              {isLoading
                ? <Loader width={70} height={70}/>
                : <>
                    {error
                      ? <Page404/>
                      : <>
                          {(!!data && !!offlineAdvertisingType.length) && Object.keys(data).map(type =>
                            (!!data[type].length &&
                              <BlockWrapper key={type}>
                                  <BlockTitle>{offlineAdvertisingType.find(el => el.value === type).label}</BlockTitle>
                                  {data[type].map(item =>
                                    <MaterialItem
                                      key={item.id}
                                      values={modifyData(type, item)}
                                      fetchDelete={() => fetchDelete(item.id)}
                                    />
                                  )}
                              </BlockWrapper>
                            )
                          )}
                      </>
                    }
                </>
              }
            </ContentWrapper>
        </Wrapper>
    )
};

const RedSpan = styled.span`
    color: red;
`;

export default Content;
